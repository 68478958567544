import React from "react"
import { Container, Row, Col, Ratio, Button } from "react-bootstrap"
import { connect } from "react-redux"
import { clearCart, removeItem } from "./actions/cartActions"
import { GatsbyImage } from "gatsby-plugin-image"
import { cartImage } from './layout.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"


class Cart extends React.Component {
    handleClick = () => {
        this.props.clearCart()
    }
    removeItem = (id) => {
        this.props.removeItem(id)
    }
    render() {
        console.log(this.props)
        let addedItems = this.props.cartItems && this.props.cartItems.length ?
            (
                this.props.cartItems.map(item => {
                    return (
                        <Row>
                            <Col xs={6} >
                                <p className={`d-flex align-items-center`}>
                                    <Ratio aspectRatio="1x1" className={`d-inline-block overflow-hidden me-5 border rounded-circle ${cartImage}`}>
                                        <GatsbyImage className={`position-absolute`} image={item.image} alt="Testing image" />
                                    </Ratio>
                                    <strong>{item.name}</strong>
                                </p>
                            </Col>
                            <Col className={`d-flex align-items-center`}><p>{item.quantity}</p></Col>
                            <Col className={`d-flex align-items-center justify-content-end`}><p className={`text-end`}><strong>${item.price.toFixed(2)}</strong></p></Col>
                            <Col xs={1} className={`d-flex align-items-center justify-content-end position-relative`}><Button className={`position-absolute top-40 start-100 translate-middle badge`} variant={"dark"} onClick={() => this.removeItem(item.id)}><small><strong>X</strong></small></Button></Col>
                        </Row>
                    )
                })
            ) :
            (
                <p>Nothing</p>
            )
        return (
            <Container>
                <Row className={`mb-5`}>
                    <h3>Shopping Cart</h3>
                </Row>
                {this.props.cartItems && this.props.cartItems.length > 0 &&
                    <Row>
                        <Col xs={6}><p className={`text-muted`}><small>Product</small></p></Col>
                        <Col><p className={`text-muted`}><small>Quantity</small></p></Col>
                        <Col><p className={`text-muted text-end`}><small>Price</small></p></Col>
                        <Col xs={1} >&nbsp;</Col>
                    </Row>}
                {addedItems}
                <Col className={`text-end`}>
                    <Button variant="danger" onClick={() => { this.handleClick() }} className={`rounded-pill fw-bold px-4 text-uppercase`}>Empty Cart</Button>
                </Col>
                {this.props.cartItems && this.props.cartItems.length > 0 &&
                    <>
                    <Row className={`align-items-center mt-5`}>
                        <Col xs={6}>
                            <Link className={`text-dark text-decoration-none`} to="/"><FontAwesomeIcon className={`me-2`} icon={faArrowLeft} /><strong>Continue Shopping</strong></Link>
                        </Col>
                        <Col className={`text-end`}>
                            Sub-Total <strong>${this.props.total.toFixed(2)}</strong>
                        </Col>
                        <Col className={`text-end`}>
                            <Link to="/checkout"><Button variant="warning" className={`rounded-pill fw-bold px-4 text-uppercase`}>Checkout</Button></Link>
                        </Col>
                    </Row>
                    </>}
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cartItems: state.addedItems,
        total: state.total
    }
}

const mapDispatchToProps = (dispatch) => {   
    return {
        clearCart: ()=>{dispatch(clearCart())}, 
        removeItem: (id) => {dispatch(removeItem(id))}
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(Cart)