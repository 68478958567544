import React from "react"
import Layout from '../components/layout'
import Cart from "../components/cart"

const CartScreen = () => {
    return (
        <Layout>
            <Cart></Cart>
        </Layout>
    )
}
export default CartScreen